//  WARNING: DON'T CHANGE THIS FILE MANUALLY
//  This file has been generated by the builder tool.
//  All changes you manually make to this file will be reverted on the next build
.icon-container {
    flex: none;
    height: 60px;
    position: relative;
    width: 24px;
    .icon {
        display: contents;
    }
}