//  WARNING: DON'T CHANGE THIS FILE MANUALLY
//  This file has been generated by the builder tool.
//  All changes you manually make to this file will be reverted on the next build
#icon-email-svg {
    user-select: none;
    width: 100%;
    height: 100%;
    display: inline-block;
    fill: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    flex-shrink: 0;
}