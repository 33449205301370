//  WARNING: DON'T CHANGE THIS FILE MANUALLY
//  This file has been generated by the builder tool.
//  All changes you manually make to this file will be reverted on the next build
#subscription-form {
    font-family: "Libre Franklin", sans-serif;
    font-size: 14px;
    .email-line {
        .form-control {
            border: 1px solid $text-dark;
            display: relative;
            padding: 10px;
        }
        input {
            display: block;
            border: none;
            padding: 0;
            line-height: 30px;
            width: 100%;
        }
    }
    .tick-line {
        font-size: 12px;
        line-height: 12px;
        padding: 24px 0;
        input {
            width: 12px;
            height: 12px;
        }
    }
    .benefits-text {
        font-size: 14px;
        ul {
            padding-left: 0;
            list-style: none;
        }
        li {
            padding-bottom: 10px;
        }
        .title {
            font-size: 18px;
            font-style: italic;
            font-weight: 400;
        }
        
    }
    .submit-button {
        background: $text-dark;
        color: $background-lightest;
        border: none;
        padding: 20px;
        width: 100%;
        cursor: pointer;
        margin-bottom: 15px;
    }
}