//  WARNING: DON'T CHANGE THIS FILE MANUALLY
//  This file has been generated by the builder tool.
//  All changes you manually make to this file will be reverted on the next build
$background-light: #BC8A5F;
$background-lighter: #efefef;
$background-lightest: #ffffff;
$text-dark: #603808;

@import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Display&family=Libre+Franklin:wght@100;400&display=swap');

// media queries mixins:
@mixin for-desktop-up {
    @media (min-width: 992px) { @content; }
}

html, body {
    background: $background-light;
    color: $text-dark;
    -webkit-font-smoothing: antialiased;
}