//  WARNING: DON'T CHANGE THIS FILE MANUALLY
//  This file has been generated by the builder tool.
//  All changes you manually make to this file will be reverted on the next build
footer {
    background: $text-dark;
    color: $background-lightest;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 40px;
    justify-content: center;
    align-items: center;
    height: min-content;
    position: relative;
    min-height: 300px;

    .social-media {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 20px;
    }

    .copyright a:link, .copyright, .copyright a:-webkit-any-link {
        text-decoration: none;
        color: $background-lightest;
        font-size: 12px;
        font-family: "Libre Franklin", sans-serif;
    }
}