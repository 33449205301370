//  WARNING: DON'T CHANGE THIS FILE MANUALLY
//  This file has been generated by the builder tool.
//  All changes you manually make to this file will be reverted on the next build
header {
    background: white;
    border-bottom: 1px solid $background-lighter;
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 24px;
    height: 80px;
    justify-content: center;
    padding: 24px 48px;
    position: relative;
    text-transform: uppercase;
    .logo {
        a {
            font-family: 'Libre Caslon Display', sans-serif;
            font-size: 18px;
            color: $text-dark;
            letter-spacing: 1px;
            text-decoration: none;
        }
        align-content: center;
        align-items: center;
        display: flex;
        flex: 1 0 0px;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        overflow: visible;
        padding: 0;
        position: relative;
    }
    .social-media-link {
        a {
            font-family: 'Libre Franklin', sans-serif;
            font-size: 12px;
            color: $text-dark;
            letter-spacing: 2px;
            text-decoration: none;
        }
    }
}
