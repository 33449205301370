//  WARNING: DON'T CHANGE THIS FILE MANUALLY
//  This file has been generated by the builder tool.
//  All changes you manually make to this file will be reverted on the next build
.hero {
    position: relative;
    background-color: $background-lightest;
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0px;
    height: min-content;
    justify-content: center;
    overflow: hidden;
    padding: 0;
    width: 100%;
    // Large devices (desktops, 992px and up)
    @include for-desktop-up {
        min-height: 840px;
        flex-direction: row;
    }

    .left-side, .right-side {
        align-content: center;
        align-items: center;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 10px;
        height: auto;
        justify-content: center;
        overflow: hidden;
        padding: 0;
        position: relative;
        transform: perspective(1200px);
        @include for-desktop-up {
            flex: 1 0 0px;
        }
    }
    @include for-desktop-up {
        .left-side > div {
            padding: 0 0 0 60px;
        }
    
        .right-side > div {
            padding: 0 60px 0 0;
        }
    }
    
    .right-side {
        gap: 20px;
        @include for-desktop-up {
            height: 841px;
        }
    }

    img {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
        image-rendering: auto;
        top: 0;
        right: 0;
    }
    .content {
        align-content: flex-start;
        align-items: flex-start;
        display: flex;
        flex: none;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 24px;
        height: min-content;
        justify-content: center;
        position: relative;
        z-index: 1;
        max-width: 400px;
        padding: 0 20px;

        @include for-desktop-up {
            padding: auto;
        }
    }

    .moto {
        font-family: "Libre Caslon Display", sans-serif;
        font-size: 56px;
        letter-spacing: -2.6px;
        line-height: 1;
        .chic {
            font-family: "Libre Franklin", sans-serif;
            font-style: italic;
            font-size: 64px;
            font-weight: 100;
        }
    }

    .description {
        font-size: 16px;
        font-family: "Libre Franklin", sans-serif;
    }
}