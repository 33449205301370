//  WARNING: DON'T CHANGE THIS FILE MANUALLY
//  This file has been generated by the builder tool.
//  All changes you manually make to this file will be reverted on the next build
.hero-blog {
    min-height: 485px;
    .background {
        opacity: 0.3;
        img {
            display: block;
            object-fit: cover;
        }
    }
}